import React from 'react';
import './App.css';
import Header from './Components/Header';
import Footer from './Components/Footer';
import About from './Components/About';
import Resume from './Components/Resume';
import Contact from './Components/Contact';
// import Gallery from './Components/Gallery';
import { data } from './data/data';

const App = () => {
  return (
    <div className="App">
      <Header data={data.main} />
      <About data={data.main} />
      <Resume data={data.companyGoal} />
      {/* <Gallery data={data.galleryImages} /> */}
      <Contact data={data.main} />
      <Footer data={data.main} />
    </div>
  );
};

export default App;
