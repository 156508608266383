export const data = {
  main: {
    name: 'Кинолошко здружение Кани Ди Луп – Штип',
    description:
      'Кинолошкото здружение Кани Ди Луп во Штип е формирано со иницијатива на неколку ентузијасти, љубители на домашните миленици секако најмногу во најверниот пријател на човекот - КУЧЕТО.',
    logo: 'logo.png',
    image: 'logo.png',
    bio:
      'За првпат во Штип, во месец декември 2020год., конечно идејата станува реалност. Денес Кинолошко здружение Кани Ди Луп брои десетичи членови, има своја веб страна, Facebook страна и своја канцеларија, каде функционира и каде ги спроведува своите активносто.',
    contactmessage:
      'Ентузијастите се Нико, Киро, Пепи ... и други кои несебично се вложуваат и сакаат кинологија во права смисла на зборот. Ако имате потреба од било каков совет/прашање можете слободно да ги контактирате преку следнава форма или директно на е-маил/фејсбук.',
    email: 'kanidilup@gmail.com',
    phone: '555-555-5555',
    github: '',
    project: '',
    address: {
      street: '(Your Street)',
      city: '(Your City)',
      state: '(Your State)',
      zip: '(Your Zip/Postal Code)',
    },
    social: [
      {
        name: 'facebook',
        url: 'https://www.facebook.com/pethotelniko/',
        className: 'fa fa-facebook',
      },
      {
        name: 'instagram',
        url: '#',
        className: 'fa fa-instagram',
      },
    ],
  },
  companyGoal: {
    heading: 'Која е улогата на здружението?',
    description:
      'Здружението има за цел да ја унапреди кинологијата во градот Штип и околината, со помагање и давање совети на сопствениците на кучиња, советување при избор на домашни миленици, организација на стручни семинари и советувања, како и организација на домашни и интернационални изложби од компетитивен аспект.',
  },
  galleryImages: [
    {
      title: '',
      imageUrl: '',
    },
    {
      title: '',
      image: '',
    },
    {
      title: '',
      imageUrl: '',
    },
    {
      title: '',
      imageUrl: '',
    },
    {
      title: '',
      imageUrl: '',
    },
  ],
};
