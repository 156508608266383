import React from 'react';
import ParticlesBg from 'particles-bg';

const Header = (props) => {
  const name = props.data.name;
  const logo = props.data.logo;
  const description = props.data.description;
  const networks = props.data.social.map(function (network) {
    return (
      <li key={network.name}>
        <a href={network.url} target="blank">
          <i className={network.className}></i>
        </a>
      </li>
    );
  });

  return (
    <header id="home">
      <div className="logo">
        <a className="smoothscroll" href="#home">
          <img src={`images/${logo}`} alg="Кани Ди Луп лого" />
        </a>
      </div>
      <ParticlesBg type="circle" bg={true} />
      <nav id="nav-wrap">
        <a className="mobile-btn" href="#nav-wrap" title="Отвори мени">
          Отвори мени
        </a>
        <a className="mobile-btn" href="#" title="Затвори мени">
          Затвори мени
        </a>

        <ul id="nav" className="nav">
          <li className="logo-navbar">
            <a className="smoothscroll" href="#home">
              <img alt="Кани Ди Луп лого" src="images/logo.png" />
            </a>
          </li>
          <li className="current">
            <a className="smoothscroll" href="#home">
              Дома
            </a>
          </li>
          <li>
            <a className="smoothscroll" href="#about">
              За нас
            </a>
          </li>
          <li>
            <a className="smoothscroll" href="#portfolio">
              Галерија
            </a>
          </li>
          <li>
            <a className="smoothscroll" href="#contact">
              Контакт
            </a>
          </li>
        </ul>
      </nav>

      <div className="row banner">
        <div className="banner-text">
          <h1 className="responsive-headline">{name}</h1>
          <h3>{description}</h3>
          <hr />
          <ul className="social">{networks}</ul>
        </div>
      </div>

      <p className="scrolldown">
        <a className="smoothscroll" href="#about">
          <i className="icon-down-circle"></i>
        </a>
      </p>
    </header>
  );
};

export default Header;
