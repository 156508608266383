import React from 'react';

const About = (props) => {
  const name = props.data.name;
  const logo = 'images/' + props.data.logo;
  const bio = props.data.bio;
  const street = props.data.address.street;
  const city = props.data.address.city;
  const state = props.data.address.state;
  const zip = props.data.address.zip;
  const phone = props.data.phone;
  const email = props.data.email;
  const resumeDownload = props.data.resumedownload;

  return (
    <section id="about">
      <div className="row">
        <div className="three columns center">
          <img
            className="profile-pic"
            src={logo}
            alt="Kanidilup"
          />
        </div>
        <div className="nine columns main-col">
          <h2>За нас</h2>

          <p>{bio}</p>
          <div className="row">
            <div className="columns contact-details">
              <h2>Детали за контакт</h2>
              <p className="address">
                {/* <span>{name}</span>
                <br />
                <span>
                  {street}
                  <br />
                  {city} {state}, {zip}
                </span>
                <br />
                <span>{phone}</span>
                <br /> */}
                <span>{email}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
