import React from 'react';

const Resume = (props) => {
  const goal = props.data;

  return (
    <section id="resume">
      <div className="row work">
        <div className="three columns header-col">
          <h1>
            <span>Нашата цел</span>
          </h1>
        </div>

        <div className="nine columns main-col">
          <h3>{goal.heading}</h3>
          <p>{goal.description}</p>
        </div>
      </div>

    </section>
  );
};

export default Resume;
